import axios from 'axios'
import { Toast } from 'cube-ui'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api 的 base_url
  timeout: 1200000 // 请求超时时间
})

// response 拦截器
service.interceptors.response.use(
  response => {
    const code = response.status
    if (code < 200 || code > 300) {
      Toast.$create({
        txt: response.message,
        time: 3000,
        type: 'error'
      }).show()
      return Promise.reject(new Error('error'))
    } else {
      return response
    }
  },
  error => {
    let code = 0
    try {
      code = error.response.status
    } catch (e) {
      if (error.toString().indexOf('Error: timeout') !== -1) {
        Toast.$create({
          txt: '网络请求超时',
          time: 3000,
          type: 'error'
        }).show()
        return Promise.reject(error)
      } else if (error.toString().indexOf('Error: Network Error') !== -1) {
        Toast.$create({
          txt: '网络错误',
          time: 3000,
          type: 'error'
        }).show()
        return Promise.reject(error)
      }
    }
    switch (code) {
      case 400:
        error.message = '请求错误'
        break
      case 401:
        error.message = '登录过期'
        break
      case 403:
        error.message = '拒绝访问'
        break
      case 404:
        error.message = `请求地址出错: ${error.response.config.url}`
        break
      case 408:
        error.message = '请求超时'
        break
      case 500:
        if (error.response.data.msg) {
          error.message = error.response.data.msg
        } else {
          error.message = '服务器内部错误'
        }
        break
      case 501:
        error.message = '服务未实现'
        break
      case 502:
        error.message = '网关错误'
        break
      case 503:
        error.message = '服务不可用'
        break
      case 504:
        error.message = '网关超时'
        break
      case 505:
        error.message = 'HTTP版本不受支持'
        break
      default:
        error.message = '请求未知错误'
        break
    }
    // Toast.$create({
    //   txt: '接口请求失败',
    //   time: 3000,
    //   type: 'error'
    // }).show()
    return Promise.reject(error)
  }
)

export default ({ url, data, method, params, headers = {}, extraConfig = {}, responseType }) => {
  const { loading = true, showError = true } = extraConfig
  const loadingToast = Toast.$create({
    txt: '请求中，请稍后',
    time: 0,
    mask: true
  }, false)
  loading && loadingToast.show()
  return new Promise((resolve, reject) => {
    service.request({
      url, method, params, data, headers, responseType
    }).then(response => {
      loading && loadingToast.hide()
      const res = response.data
      if (responseType) {
        resolve(res)
      } else {
        if (res.code !== 0 && showError) {
          Toast.$create({
            txt: res.msg,
            time: 3000,
            type: 'error'
          }).show()
        }
        resolve(res)
      }
    }).catch(err => {
      loading && loadingToast.hide()
      reject(err)
    })
  })
}

export const filters = {
  kiloReg: (str) => { // 正则千分符
    const num = str ? str.toFixed(2) : '0.00'
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
  formatDate: (string, reg) => {
    if (!string) return ''
    const dateObject = new Date(string)
    const year = dateObject.getFullYear()
    const month = dateObject.getMonth() + 1
    const date = dateObject.getDate()
    const hour = dateObject.getHours()
    const minutes = dateObject.getMinutes()
    const seconds = dateObject.getSeconds()
    return !reg ? year + '-' + format(month) + '-' + format(date) + ' ' + format(hour) + ':' + format(minutes) + ':' + format(seconds) : reg.replace('yyyy', year).replace('MM', format(month)).replace('dd', format(date)).replace('hh', format(hour)).replace('mm', format(minutes)).replace('ss', format(seconds))
  },
  mobileHide: (mobile) => {
    // 手机号脱敏
    return mobile ? mobile.replace(/(\d{3})\d*(\d{4})/, '$1****$2') : ''
  },
  // UTC时间转GMT时间
  UTC2GMTDate: (string) => {
    if (!string) return ''
    const datee = new Date(string).toJSON()
    return new Date(new Date(datee) + 8 * 3600 * 1000).toISOString().replace(/T/g, '').replace(/\.[\d]{3}Z/, '')
  }
}

function format(num) {
  return num >= 10 ? num : '0' + num
}

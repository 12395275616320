import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  redirect: '/validate'
}, {
  path: '/validate',
  name: 'Validate',
  component: () => import('@/views/validate'),
  meta: {
    title: ''
  }
}, {
  path: '/upload-id-card',
  name: 'UploadIdCard',
  component: () => import('@/views/upload-id-card'),
  meta: {
    title: '身份证上传'
  }
}, {
  path: '/verification',
  name: 'Verification',
  component: () => import('@/views/verification'),
  meta: {
    title: '实名认证'
  }
}, {
  path: '/desire',
  name: 'Desire',
  component: () => import('../views/desire'),
  meta: {
    title: '意愿认证'
  }
}, {
  path: '/result',
  name: 'Result',
  component: () => import('../views/result'),
  meta: {
    title: '电签成功'
  }
}, {
  path: '/preview-contract',
  name: 'PreviewContract',
  component: () => import('../views/contract-preview'),
  meta: {
    title: '查看合同'
  }
}, {
  path: '/preview-contract-test',
  name: 'PreviewContract',
  component: () => import('../views/contract-preview-test'),
  meta: {
    title: '查看合同'
  }
}]

export default new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  base: '/signature/',
  routes
})

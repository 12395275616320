import router from './routers'
import store from '@/store'

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  !store.getters.merchantInfo.merchantName && store.dispatch('GetMerchantInfo', to.query.subMerchantId)
  if ((to.path === '/upload-id-card' || to.path === '/verification') && !store.getters.decodeInfo) {
    store.dispatch('GetDecodeInfo', to.query).then(data => {
      if (to.path === '/upload-id-card' && data.isUploadIdcard === 0) {
        next({ path: '/verification', query: to.query, replace: true })
      } else {
        next()
      }
    }).catch(() => {
      next()
    })
  } else {
    next()
  }
})

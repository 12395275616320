import request from '@/utils/request'

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
}

/** config可配置参数
 * loading: 是否显示请求中等待框
 * showError: 请求出现错误时是否展示错误提示
 */
const base = `${process.env.VUE_APP_EVENT_URL}/api`

// 埋点
export function bpevent(data) {
  return request({
    url: base + '/event/bpevent',
    method: 'post',
    data: JSON.stringify(data),
    headers,
    extraConfig: {
      loading: false
    }
  })
}

import {
  decodeUrl
} from '@/api/verification'
import {
  getMerchantLogo
} from '@/api/validate'

const user = {
  state: {
    decodeInfo: null,
    merchantInfo: {},
    isOem: true,
    merchantSuccess: false,
    inductApplet: false
  },

  mutations: {
    SET_DECODE_INFO(state, decodeInfo) {
      state.decodeInfo = decodeInfo
    },
    SET_MERCHANT_INFO(state, merchantInfo) {
      state.merchantInfo = merchantInfo
    },
    SET_IS_OEM(state, isOem) {
      state.isOem = isOem
    },
    SET_MERCHANT_SUCCESS(state, flag) {
      state.merchantSuccess = flag
    },
    SET_InductApplet(state, inductApplet) {
      state.inductApplet = inductApplet
    }
  },

  actions: {
    // 获取解密信息
    GetDecodeInfo({
      commit
    }, decodeParams) {
      return new Promise((resolve, reject) => {
        decodeUrl(decodeParams).then(res => {
          if (res.code === 0) {
            const {
              subMerchantId,
              templateId
            } = decodeParams
            commit('SET_DECODE_INFO', {
              ...res.data,
              subMerchantId,
              templateId
            })
            resolve(res.data)
          } else {
            reject(new Error(res.msg))
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetMerchantInfo({
      commit
    }, subMerchantId) {
      commit('SET_MERCHANT_SUCCESS', false)
      getMerchantLogo({
        subMerchantId
      }).then(res => {
        if (res.code === 0) {
          commit('SET_MERCHANT_SUCCESS', true)
          commit('SET_MERCHANT_INFO', res.data)
          commit('SET_IS_OEM', !!res.data.oemId)
          commit('SET_InductApplet', res.data.inductApplet)
        }
      })
    }
  }
}

export default user

import request from '@/utils/request'

export function checkPhone(data) {
  return request({
    url: '/api/contract/checkPhone',
    method: 'post',
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    },
    extraConfig: {
      showError: false
    }
  })
}

export function sendSms(data) {
  return request({
    url: '/api/contract/resendSms',
    method: 'post',
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function checkSms(data) {
  return request({
    url: '/api/contract/checkSms',
    method: 'post',
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function getMerchantLogo(params) {
  return request({
    url: '/api/contract/getMerchantLogo',
    method: 'get',
    params
  })
}

import request from '@/utils/request'

export function decodeUrl(data) {
  return request({
    url: '/api/contract/decodeUrl',
    method: 'post',
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function signTaskStart(data) {
  return request({
    url: '/api/contract/signTaskStart',
    method: 'post',
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function getBankName(params) {
  return request({
    url: '/api/contract/getBankName',
    method: 'GET',
    params,
    extraConfig: {
      loading: false
    }
  })
}

export function previewContract(params) {
  return request({
    url: '/api/contract/previewContract',
    method: 'GET',
    params,
    extraConfig: {
      loading: false
    }
  })
}

export function previewContractImg(params) {
  return request({
    url: '/api/contract/previewContractImg',
    method: 'GET',
    params,
    extraConfig: {
      loading: false
    }
  })
}

export function inductApplet(params) {
  return request({
    url: '/api/contract/inductApplet',
    method: 'GET',
    params,
    extraConfig: {
      loading: false
    }
  })
}

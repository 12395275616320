import Vue from 'vue'
import './cube-ui'
import App from './App.vue'
import router from './router/routers'
import './router/index'
import store from './store'
import { filters } from './utils/filters'
import './assets/style/common.less'
import {
  bpevent
} from '@/api/bpevent'

Vue.config.productionTip = false
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

// 埋点
Vue.prototype.$bpevent = function (action, page, system, uuid, extraparam) {
  const params = {
    action,
    page,
    system,
    uuid,
    extraparam
  }
  bpevent(params)
}

// 搜索引擎配置
const head = document.getElementsByTagName('head')
const meta = document.createElement('meta')
meta.name = 'robots'
meta.content = 'noindex,nofollow'
head[0].appendChild(meta)
// 搜索配置

// eslint-disable-next-line no-use-before-define
const _hmt = _hmt || [];
(function () {
  const hm = document.createElement('script')
  hm.src = 'https://hm.baidu.com/hm.js?e446bdcdebc3138a1ef19f80714f7702'
  const s = document.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(hm, s)
})()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
